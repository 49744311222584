import React, { useState, useEffect, useRef } from "react";
import Title from "../Title/Title";
import Desc from "../Desc/Desc";
import Button from "../Button/Button";
import Paragraph from "../Paragraph/Paragraph";
import styles from "./Result.module.scss";
import classNames from "classnames";
import { SITE_URL } from "../../state/consts";
import { IconButton, Snackbar } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { FacebookShareButton, WhatsappShareButton } from "react-share";

import { FacebookIcon, WhatsappIcon } from "react-share";

function Result({
  score,
  title,
  text,
  imageSrc,
  posterContainer,
  optionsContainer,
  handlerBack,
}) {
  const [offset, setOffset] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");
  const [showConditions, setShowConditions] = useState(false);
  const textAreaRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setOffset(true);
      } else {
        setOffset(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const ImageURL = SITE_URL + imageSrc;

  // кнопка скопировать
  const [open, setOpen] = useState(false);

  const testClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(window.location.toString());
  };

  function copyToClipboard(e) {
    textAreaRef.current.select();
    console.log(textAreaRef.current.value);
    document.execCommand("copy");
    e.target.focus();
    setCopySuccess(
      <div className={styles.copySuccess}>O link foi copiado</div>
    );

    setTimeout(() => {
      setCopySuccess(
        <div className={classNames(styles.copySuccess, styles.displayNone)}>
          O link foi copiado
        </div>
      );
    }, "1500");
  }

  return (
    <>
      {!showConditions ? (
        <>
          <div className={posterContainer}>
            <img src={imageSrc} className={styles.image} alt="" />
          </div>
          <div className={classNames(optionsContainer, styles.before)}>
            <div
              className={classNames({
                [styles.resultPosterContainer]: true,
                [styles.onScrollOption]: offset,
              })}
            >
              <div className={styles.contentWrapper}>
                <div className={styles.score}>{score}</div>
                <Title value={title} />
                {text}
              </div>

              <div className={styles.promoBox}>
                <div className={styles.promoButtonsWrapper}>
                  <div className={styles.promoWrapper}>
                    <div className={styles.inputPromoWrapper}>
                      <form className={styles.form}>
                        <input
                          ref={textAreaRef}
                          value="MAXA10"
                          className={styles.inputPromo}
                          readOnly
                        />
                      </form>

                      <button onClick={copyToClipboard} className={styles.copy}>
                        <ContentCopyIcon />
                      </button>
                    </div>
                  </div>

                  <div className={styles.buttonBox}>
                    <a
                      href="https://shopee.com.br/max.atributos?categoryId=100636&entryPoint=ShopByPDP&itemId=18499259450"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        value="Ativar o código promocional"
                        className={styles.buttonPresent}
                      />
                    </a>

                    <div
                      className={styles.buttonBoxLink}
                      onClick={() => {
                        setShowConditions(true);
                      }}
                    >
                      Termos e condições da promoção
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={classNames(
                  styles.contentWrapper,
                  styles.socialWrapper
                )}
              >
                <Paragraph value="Compartilhar os resultados" />
                <div className={styles.social}>
                  <FacebookShareButton
                    url={SITE_URL}
                    title="Ganhe um desconto em acessórios da marca💥 Responda ao quiz e obtenha 10% de desconto na compra de uma garrafa térmica para água e um boné da marca. Clique já!"
                    image={ImageURL}
                    hashtag="#Maxim"
                    id="facebook"
                  >
                    <FacebookIcon
                      size={48}
                      round={true}
                      className={classNames(styles.icon, styles.facebook)}
                    />
                  </FacebookShareButton>

                  <WhatsappShareButton
                    url={SITE_URL}
                    title="Ganhe um desconto em acessórios da marca💥 Responda ao quiz e obtenha 10% de desconto na compra de uma garrafa térmica para água e um boné da marca. Clique já"
                    image={ImageURL}
                    id="whatsapp"
                  >
                    <WhatsappIcon
                      size={48}
                      round={true}
                      className={classNames(styles.icon, styles.whatsapp)}
                    />
                  </WhatsappShareButton>

                  <div id="sharelink">
                    <IconButton
                      onClick={testClick}
                      color="primary"
                      className={styles.share}
                    >
                      <ShareIcon />
                    </IconButton>
                    <Snackbar
                      message="O link foi copiado"
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      autoHideDuration={1000}
                      onClose={() => setOpen(false)}
                      open={open}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {copySuccess}
        </>
      ) : (
        <>
          <div className={styles.posterContainer}>
            <img src={imageSrc} className={styles.image} alt="" />
          </div>
          <div className={styles.resultContainer} m2>
            <div
              className={classNames({
                [styles.resultContainerBox]: true,
                [styles.onScrollOption]: offset,
              })}
            >
              <div>
                <Paragraph value="Cupom de desconto válido entre 22/07/2024 e 21/08/2024 e cumulável apenas com outros cupons de desconto, conforme regras das plataforma de vendas. Cupom válido para os 53 primeiros clientes que o utilizarem." />
                <Button
                  className={styles.buttonConditions}
                  value="Kembali"
                  onClick={() => {
                    setShowConditions(false);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Result;
