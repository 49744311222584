const questions = [
  // 1 Слайд \\ Бейсболка [1]
  {
    questionDesc:
      "Um boné é o acessório perfeito. Há três tipos de bonés em nossa coleção. Eles oferecem excelente proteção contra o sol e são bem estilosos.",
    questionText: "Na sua opinião, há quantos anos o boné existe?",
    answerOptions: [
      {
        answerText: "30",
        answerResult:
          "O boné apareceu em sua forma habitual em 1954. Ele foi lançado pela empresa New Era para os atletas americanos.",
        result: false,
      },
      {
        answerText: "50",
        answerResult:
          "O boné apareceu em sua forma habitual em 1954. Ele foi lançado pela empresa New Era para os atletas americanos.",
        result: false,
      },
      {
        answerText: "70",
        answerResult:
          "O boné apareceu em sua forma habitual em 1954. Ele foi lançado pela empresa New Era para os atletas americanos.",
        result: true,
      },
      {
        answerText: "100",
        answerResult:
          "O boné apareceu em sua forma habitual em 1954. Ele foi lançado pela empresa New Era para os atletas americanos.",
        result: false,
      },
    ],
    image: "images/1.webp",
  },

  // 2 слайд \\ Цвет года [4]
  {
    questionDesc:
      "Desde 2000, o Pantone Colour Institute escolhe a cor mais atual a cada ano. O preto clássico é um símbolo de estilo, e foi nessa cor que as camisetas da marca da Maxim foram confeccionadas.",
    questionText:
      "Quantas vezes o preto foi a cor do ano de acordo com o Pantone Colour Institute?",
    answerOptions: [
      {
        answerText: "0",
        answerResult:
          "O preto nunca foi a cor do ano segundo o Pantone, embora seja amado por milhões de pessoas em todo o mundo.",
        result: true,
      },
      {
        answerText: "1",
        answerResult:
          "O preto nunca foi a cor do ano segundo o Pantone, embora seja amado por milhões de pessoas em todo o mundo.",
        result: false,
      },
      {
        answerText: "3",
        answerResult:
          "O preto nunca foi a cor do ano segundo o Pantone, embora seja amado por milhões de pessoas em todo o mundo.",
        result: false,
      },
      {
        answerText: "5",
        answerResult:
          "O preto nunca foi a cor do ano segundo o Pantone, embora seja amado por milhões de pessoas em todo o mundo.",
        result: false,
      },
    ],
    image: "images/2.webp",
  },

  // 3 слайд \\ Куртки [1]
  {
    questionDesc:
      "Os personagens que usam roupas amarelas fazem parte da cultura pop há muito tempo. O Ursinho Pooh tem um casaco amarelo, que pode ser visto no pôster do filme It: A Coisa.",
    questionText: "As jaquetas da Maxim também são amarelas. Por quê?",
    answerOptions: [
      {
        answerText: "Essa é a cor tradicional dos táxis em todo o mundo",
        answerResult: "O amarelo intenso é a cor da marca do serviço Maxim.",
        result: false,
      },
      {
        answerText: "Geralmente, é o tecido amarelo que é repelente à água",
        answerResult: "O amarelo intenso é a cor da marca do serviço Maxim.",
        result: false,
      },
      {
        answerText: "Essa é a cor da marca do serviço Maxim",
        answerResult: "O amarelo intenso é a cor da marca do serviço Maxim.",
        result: true,
      },
      {
        answerText: "A cor ganhou o voto da comunidade",
        answerResult: "O amarelo intenso é a cor da marca do serviço Maxim.",
        result: false,
      },
    ],
    image: "images/3.webp",
  },

  // 4 слайд \\ Бутылка [3]
  {
    questionDesc:
      "Design elegante e volume ideal de 0,5 litro - essa é a garrafa da Maxim.",
    questionText:
      "Qual você acha que é o volume da maior garrafa de vidro do mundo?",
    answerOptions: [
      {
        answerText: "+50 litros",
        answerResult:
          "A garrafa gigantesca para o Guinness Book: o Livro dos Recordes foi feita na Suíça. Ela tem o volume de 3.100 litros e a largura e altura de cerca de 4 metros. 😱",
        result: false,
      },
      {
        answerText: "+100 litros",
        answerResult:
          "A garrafa gigantesca para o Guinness Book: o Livro dos Recordes foi feita na Suíça. Ela tem o volume de 3.100 litros e a largura e altura de cerca de 4 metros. 😱",
        result: false,
      },
      {
        answerText: "+500 litros",
        answerResult:
          "A garrafa gigantesca para o Guinness Book: o Livro dos Recordes foi feita na Suíça. Ela tem o volume de 3.100 litros e a largura e altura de cerca de 4 metros. 😱",
        result: false,
      },
      {
        answerText: "+3000 litros",
        answerResult:
          "A garrafa gigantesca para o Guinness Book: o Livro dos Recordes foi feita na Suíça. Ela tem o volume de 3.100 litros e a largura e altura de cerca de 4 metros. 😱",
        result: true,
      },
    ],
    image: "images/4.webp",
  },

  // 5 слайд \\ Одежда [2]
  {
    questionDesc:
      "A história da camisa de manga longa começou no século XIX. Ao longo dos anos, os materiais mudaram e novos estilos foram criados. Por exemplo, as camisas de manga longa da Maxim são inspiradas nos uniformes dos jogadores de futebol.",
    questionText: "Escolha um fato verdadeiro sobre essa peça de roupa:",
    answerOptions: [
      {
        answerText:
          "Originalmente, essas camisas eram usadas apenas por homens",
        answerResult:
          "Originalmente, esse tipo de roupa era usado pelos homens como roupa íntima. Somente no século XX as camisas de manga longa passaram a fazer parte do guarda-roupa cotidiano das mulheres.",
        result: true,
      },
      {
        answerText: "A camisa de manga longa foi popularizada por Coco Chanel",
        answerResult:
          "Originalmente, esse tipo de roupa era usado pelos homens como roupa íntima. Somente no século XX as camisas de manga longa passaram a fazer parte do guarda-roupa cotidiano das mulheres.",
        result: false,
      },
      {
        answerText:
          "Anteriormente, as camisas de manga longa eram apenas brancas",
        answerResult:
          "Originalmente, esse tipo de roupa era usado pelos homens como roupa íntima. Somente no século XX as camisas de manga longa passaram a fazer parte do guarda-roupa cotidiano das mulheres.",
        result: false,
      },
      {
        answerText:
          "Na composição das camisas de manga longa devem ser usados somente os tecidos 100% algodão",
        answerResult:
          "Originalmente, esse tipo de roupa era usado pelos homens como roupa íntima. Somente no século XX as camisas de manga longa passaram a fazer parte do guarda-roupa cotidiano das mulheres.",
        result: false,
      },
    ],
    image: "images/5.webp",
  },

  // 6 слайд \\ Рюкзак [3]
  {
    questionDesc:
      "Em breve, teremos em nossa loja as bolsas de marca. Elas são espaçosas e compactas.",
    questionText: "Você consegue adivinhar o volume da bolsa pela foto?",
    answerOptions: [
      {
        answerText: "3 litros",
        answerResult:
          "O volume máximo da bolsa da marca é de 10 litros. Todos os produtos da coleção da Maxim caberão perfeitamente nela.",
        result: false,
      },
      {
        answerText: "5 litros",
        answerResult:
          "O volume máximo da bolsa da marca é de 10 litros. Todos os produtos da coleção da Maxim caberão perfeitamente nela.",
        result: false,
      },
      {
        answerText: "10 litros",
        answerResult:
          "O volume máximo da bolsa da marca é de 10 litros. Todos os produtos da coleção da Maxim caberão perfeitamente nela.",
        result: true,
      },
      {
        answerText: "20 litros",
        answerResult:
          "O volume máximo da bolsa da marca é de 10 litros. Todos os produtos da coleção da Maxim caberão perfeitamente nela.",
        result: false,
      },
    ],
    image: "images/6.webp",
  },
];

export default questions;
